import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios";

export default function gridhouseARAPUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)
  const sendPriceTotal = ref(0)
  const groupPriceTotal = ref(0)
  const dueReceiveTotal = ref(0)
  const carriagePriceTotal = ref(0)
  const sortPriceTotal = ref(0)
  const storePriceTotal = ref(0)
  const duePayTotal = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: 'gh_id', label: '网格仓ID'},
    { key: 'grid_name', label: '网格仓名称'},
    { key: 'year', label: '年月'},
    { key: 'send_price', label: '送货收费总金额'},
    { key: 'group_price', label: '团点数总收费'},
    { key: 'due_receive', label: '应收总金额'},
    { key: 'carriage_price', label: '总运费'},
    { key: 'sort_price', label: '总分拣费'},
    { key: 'store_price', label: '仓储费'},
    { key: 'due_pay', label: '应付总金额'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('gh_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    axios.post('api/gridhousearap/searchGroup', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        grid_name: condition.value.grid_name,
        time: condition.value.time,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        sendPriceTotal.value = data.ext.sendPriceTotal
        groupPriceTotal.value = data.ext.groupPriceTotal
        dueReceiveTotal.value = data.ext.sendPriceTotal + data.ext.groupPriceTotal
        carriagePriceTotal.value = data.ext.carriagePriceTotal
        sortPriceTotal.value = data.ext.sortPriceTotal
        storePriceTotal.value = data.ext.storePriceTotal
        duePayTotal.value = data.ext.carriagePriceTotal + data.ext.sortPriceTotal + data.ext.storePriceTotal
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,
    sendPriceTotal,
    groupPriceTotal,
    dueReceiveTotal,
    carriagePriceTotal,
    sortPriceTotal,
    storePriceTotal,
    duePayTotal,
    // Extra Filters
  }
}
