<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--网格仓名称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="网格仓名称"
                    label-for="grid_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="grid_name"
                      size="sm"
                      v-model="condition.grid_name"
                      placeholder="请输入网格仓名称"
                  />
                </b-form-group>
              </b-col>
              <!--年月-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="年月"
                    label-for="time"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      id="time"
                      v-model="condition.time"
                      class="form-control"
                      :config="{dateFormat: 'Y-m'}"
                  />
                </b-form-group>
              </b-col>

              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <GridhouseARAPItem :gh_id="row.item.gh_id" :year="row.item.year" :month="row.item.month"></GridhouseARAPItem>
        </template>

        <!-- Columns -->
        <template #cell(gh_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ data.item.gh_id }}
        </template>

        <!--年月-->
        <template #cell(year)="data">
          {{ data.item.year +'-'+ data.item.month}}
        </template>

        <!-- 应收总金额：送货收费总金额+团点数总收费-->
        <template #cell(due_receive)="data">
          {{ isNumber(data.item.send_price) + isNumber(data.item.group_price)}}
        </template>

        <!--应付总金额：总运费+总分拣费+仓储费-->
        <template #cell(due_pay)="data">
          {{ isNumber(data.item.carriage_price) + isNumber(data.item.sort_price) + isNumber(data.item.store_price)}}
        </template>

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="3" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{sendPriceTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{groupPriceTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{dueReceiveTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{carriagePriceTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{sortPriceTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{storePriceTotal}}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{duePayTotal}}</strong></span>
            </b-td>
          </b-tr>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty, second, isNumber
} from '@core/utils/filter'
import gridhouseARAPUseList from './gridhouseARAPUseList'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import GridhouseARAPItem from "@/views/apps/gridhousearapitem/GridhouseARAPItem";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    GridhouseARAPItem,
  },
  data() {
    return {
      isShowCard: false,
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      sendPriceTotal,
      groupPriceTotal,
      dueReceiveTotal,
      carriagePriceTotal,
      sortPriceTotal,
      storePriceTotal,
      duePayTotal,
      // UI
    } = gridhouseARAPUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      sendPriceTotal,
      groupPriceTotal,
      dueReceiveTotal,
      carriagePriceTotal,
      sortPriceTotal,
      storePriceTotal,
      duePayTotal,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isNumber,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
